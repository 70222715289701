import XLSXStyle from "xlsx-js-style";
import { generateTableData } from "@/utils/tables.js";

export const exportTemplate = (data, header, nameFile, length) => {
  // console.log(header);
  // console.log(length);
  // console.log(data);
  var wb = XLSXStyle.utils.book_new();
  let tableData = generateTableData(data, header);

  var ws = XLSXStyle.utils.json_to_sheet(tableData);

  var wscols = [];
  //A === 65 and 74 === J
  let tableStart = 65;
  // let tableEnd = 65 + header.length - 1;

  let value = "";
  for (let i = 1; i <= length; i++) {
    let num = i;
    let str = "";

    while (num > 0) {
      let remainder = (num - 1) % 26;
      str = String.fromCharCode(65 + remainder) + str;
      num = Math.floor((num - remainder) / 26);
    }

    value = str.length === 1 ? str : str.slice(-2);

    try {
      ws[`${value}1`].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: "FF86c5da",
          },
        },
        alignment: {
          horizontal: "center",
          wrapText: false,
        },
      };

      wscols.push({ wch: 20 });
    } catch (error) {}

    // } else {
    //   let value = str.slice(-2);
    //   ws[`${value}1`].s = {
    //     font: {
    //       bold: true,
    //     },
    //     fill: {
    //       fgColor: {
    //         rgb: "FF86c5da",
    //       },
    //     },
    //     alignment: {
    //       horizontal: "center",
    //       wrapText: false,
    //     },
    //   };

    // }

    // console.log(value);
  }
  // for (let i = tableStart; i <= tableEnd; i++) {
  //   let value;

  //   if (i >= 65 + 26) {
  //     value = `A${String.fromCharCode(tableEnd - 26)}`;
  //   } else {
  //     value = String.fromCharCode(i);
  //   }
  //   // if (ws[`${value}1`].s === undefined) break;

  //   ws[`${value}1`].s = {
  //     font: {
  //       bold: true,
  //     },
  //     fill: {
  //       fgColor: {
  //         rgb: "FF86c5da",
  //       },
  //     },
  //     alignment: {
  //       horizontal: "center",
  //       wrapText: false,
  //     },
  //   };

  // wscols.push({ wch: 20 });
  // }

  ws["!cols"] = wscols;

  let filename = `${nameFile}`;

  XLSXStyle.utils.format_cell;
  XLSXStyle.utils.book_append_sheet(wb, ws, filename);

  /* Trigg er Download with `writeFile` */
  XLSXStyle.writeFile(wb, filename + ".xlsx", { compression: true });
};
