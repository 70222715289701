export const generateHeaderData = (header, classes) => {
  let headerData = [];

  for (let value of header) {
    headerData.push({
      sortable: value.value !== "actions",
      ...value,
      class: classes ? `${classes} table-header` : "table-header a",

      align: "center",
    });
  }
  return headerData;
};

export const generateTableData = (data, header) => {
  let tableData = [];

  for (let item of data) {
    let row = {};
    for (let head of header) {
      let text = head.text;
      row[text] = item[head.value];
    }
    tableData.push(row);
  }

  return tableData;
};
