import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login/index.vue";
import Insights from "@/views/Insights/index.vue";
import Inventory from "@/views/Inventory/index.vue";
import InvUpload from "@/views/Upload/index.vue";
import Buyers from "@/views/Buyers/index.vue";
import Settings from "@/views/Settings/index.vue";
import store from "@/store/index";
import axios from "axios";
import ForgotPassword from "@/views/Password/ForgotPassword.vue";
import ResetPassword from "@/views/Password/ResetPassword.vue";
import ContainerStatus from "@/views/ContainerStatus/index.vue";
import InventoryQtySumm from "@/views/InventoryQtySumm/index.vue";
import DepotManage from "@/views/Depots/index.vue";
import DepotTermination from "@/views/Depots/DepotTermination/index.vue";
import InventoryAllocation from "@/views/InventoryAllocation/index.vue";
import Organizations from "@/views/Organizations/index.vue";
import ManageOrders from "@/views/OM/index.vue";
import AllOrders from "@/views/OM/AllOrders";
import OrderDetails from "@/views/OM/OrderDetails";
import OrderDetails2 from "@/views/OM/OrderDetails2";
import Reports from "@/views/Reports/index.vue";
import SummaryAuctions from "@/views/Auctions/SummaryAuctions.vue";
import InventoryWarnings from "@/views/InventoryWarnings/index.vue";
import CustomerDistribution from "@/views/CustomerDistribution/index.vue";
import CreateList from "@/views/CustomerDistribution/CreateList.vue";
import Summary from "@/views/CustomerDistribution/Summary.vue";
import AuctionInvites from "@/views/emails/index.vue";
import CreateAuction2 from "@/views/Auctions/CreateAuction2.vue";
import NewOrder from "@/views/OM/NewOrder.vue";
import Auctions from "@/views/Auctions/index.vue";
import SQLReports from "@/views/SQLReports/index.vue";
import { beforeEachGuard } from "@/router/guards.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/SQLReports",
    name: "SQLReports",
    component: SQLReports,
  },
  {
    path: "/inventory/warnings",
    name: "InventoryWarnings",
    component: InventoryWarnings,
  },
  {
    path: "/",
    name: "Insights",
    component: Insights,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/inventory/upload",
    name: "InvUpload",
    component: InvUpload,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/buyers",
    name: "Buyers",
    component: Buyers,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { isPublic: true, forgot_password: true },
  },
  {
    path: "/restore-password",
    component: ResetPassword,
    name: "restore-password",
    meta: { isPublic: true },
    beforeEnter: (to, from, next) => {
      let { token } = to.query;
      if (!token) {
        next("/login");
      }
      next();
    },
  },
  {
    path: "/container/status",
    name: "Container Status",
    component: ContainerStatus,
  },
  {
    path: "/inventory/qtysumm",
    name: "Inventory Summary",
    component: InventoryQtySumm,
  },
  {
    path: "/depot/terminations",
    name: "Depot Terminations",
    component: DepotTermination,
  },
  {
    path: "/depots",
    name: "Depots",
    component: DepotManage,
  },
  {
    path: "/inventory/allocations",
    name: "Inventory Allocations",
    component: InventoryAllocation,
  },
  {
    path: "/orders",
    component: ManageOrders,
    children: [
      { path: "", component: AllOrders, meta: { width: "90%" } },
      { path: "new-order", component: NewOrder, meta: { width: "100%" } },
      { path: ":docNo", component: OrderDetails2, meta: { width: "100%" } },
      // { path: "old/:docNo", component: OrderDetails, meta: { width: "90%" } },
    ],
  },

  {
    path: "/organizations",
    name: "Organizations",
    component: Organizations,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/auctions",
    component: Auctions,
    meta: {
      light: true,
    },
    children: [
      {
        path: "",
        component: SummaryAuctions,
        meta: {
          light: true,
        },
      },
      {
        path: "create-auction",
        component: CreateAuction2,
      },
    ],
  },
  {
    path: "/auction/invites",
    name: "Auctions Invites",
    component: AuctionInvites,
    meta: {
      light: true,
    },
  },
  {
    path: "/customerlist",
    component: CustomerDistribution,
    children: [
      {
        path: "",
        component: Summary,
        meta: {
          light: true,
        },
      },
      { path: "new-list", component: CreateList, meta: { light: true } },
      { path: ":id", component: CreateList, meta: { light: true } },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// const isAuthenticaded = () => {
//   return localStorage.getItem("access_token");
// };

// const getUser = () => {
//   return localStorage.getItem("user");
// };

router.beforeEach(
  beforeEachGuard
  //   (to, from, next) => {

  //   let auth = isAuthenticaded();
  //   if (!to.meta.isPublic && !auth) {
  //     return next("/login");
  //   }
  //   if (auth) {
  //     let user = getUser();
  //     store.commit("set_user", JSON.parse(user));
  //   } else {
  //     store.commit("set_user", {});
  //     localStorage.removeItem("user");
  //     localStorage.removeItem("header");
  //     // store.commit("set_user", {});
  //     if (to.path !== "/login") return next("/login");
  //   }
  //   if (to.name === "Login" && auth) {
  //     return next("/");
  //   }
  //   return next();
  // }
);

export default router;

// export { isAuthenticaded, getUser };
