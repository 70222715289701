import {
  get_status_table_history,
  getInboundStatuses,
  send_depot_template,
  get_depot_template,
  create_flag_instructions,
  get_flags_instructions,
  get_depot_details,
  delete_flag_instructions,
} from "@/helpers/api/indexv2.js";

export default {
  namespaced: true,
  state: {
    selectedRows: [], //items enable for edit
    inboundPanelFlag: false,
    inboundPanelData: {},
    flaggedInstructions: [],
    lastCheckIndex: {
      Main: 0,
      "On Hand": 0,
      "Sale Instructions": 0,
    },
    equipmentsCopy: [],
  },
  mutations: {
    setEquipmentsCopy(state, newState) {
      state.equipmentsCopy = newState;
    },
    setInboundPanelData(state, newState) {
      state.inboundPanelData = newState;
    },
    setInboundPanelFlag(state, newState) {
      state.inboundPanelFlag = newState;
    },
    setSelectedRows(state, newState) {
      state.selectedRows = newState;
    },
    addRowSelect(state, newState) {
      state.selectedRows.push(newState);
    },
    removeRowSelect(state, index) {
      state.selectedRows.splice(index, 1);
    },
    setFlaggedInstructions(state, newState) {
      state.flaggedInstructions = newState;
    },
    setLastCheckIndex(state, newState) {
      state.lastCheckIndex[newState.name] = newState.value;
    },
  },
  actions: {
    showInboundStatus({ commit }, item) {
      if (item) {
        commit("setInboundPanelData", item);
        commit("setInboundPanelFlag", true);
      } else commit("setInboundPanelFlag", false);
    },
    closeInboundStatus({ commit }, item) {
      commit("setInboundPanelFlag", false);
    },
    async getStatusTableHistory({ commit }, { EquipmentID }) {
      commit("setSpinnerShow", true, { root: true });

      const hist = await get_status_table_history(EquipmentID);

      if (hist.status === 200) {
        let res = await getInboundStatuses();
        if (res.status === 200) {
          const statusArr = res.data;

          let remapPrep = hist.data.data.map((x) => {
            return {
              date_time: x["StatusDateGiven"],
              status: x["StatusCode"],
              status_description: x["substep_descr"],
              status_location: x["StatusLocationGiven"],
              dwell: x["dwell"],
              date_time_created: x["DateCreated"],
              status_id: x["id"],
            };
          });

          remapPrep = _.uniq(remapPrep);
          // remap = remapPrep;

          let remap = remapPrep.map((j) => {
            let desc = null;

            try {
              desc = statusArr.data.filter((y) => {
                return y["Code"] == j["status"].toUpperCase().trim();
              })[0]["substep_descr"];
            } catch (error) {
              desc = null;
            }

            return {
              ...j,
              status_description: desc ? desc : "",
            };
          });

          remap = _.uniq(remap);

          const remapRoot = { table_data: remap };

          commit("setSpinnerShow", false, { root: true });

          return remapRoot;
        } else {
          //shold show a snackbar but for now --->
          console.log("err", res);
        }
      } else {
        //shold show a snackbar but for now --->
        console.log("err", hist);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async sendDepotTemplate({ commit }, { depots, email }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = email
          ? await send_depot_template({
              templateName: "depotPreArrival",
              items: depots,
              email,
            })
          : await get_depot_template({
              templateName: "depotPreArrival",
              items: depots,
            });

        commit(
          "setSnackbar",
          {
            text: email ? "Email sent." : "Template loaded",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: "Something went wrong",
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async flagInstructions({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        if (data[0].Enabled !== "N") {
          response = await create_flag_instructions(data);
        } else {
          let equipments = [data[0].EquipmentID];
          console.log(equipments);
          response = await delete_flag_instructions({
            EquipmentIds: equipments,
          });
          console.log(response);
        }

        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: error.data.message,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getFlagsInstructions({ commit }) {
      try {
        let { response } = await get_flags_instructions();
        commit("setFlaggedInstructions", response);
      } catch (error) {
        console.log(error);
      }
    },
    getFlaggedInstructionsByEquipmentID({ state }, EquipmentID) {
      return state.flaggedInstructions.filter((obj) => {
        return obj.EquipmentID === EquipmentID;
      })[0];
    },
    async getDepotDetails({}, value) {
      return new Promise(async (resolve, reject) => {
        try {
          let { data } = await get_depot_details(value);

          resolve(data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
  },
};
