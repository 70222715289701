import { get_list } from "@/helpers/api/Emails/index.js";

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    setList(state, newState) {
      state.list = newState;
    },
  },
  actions: {
    async getList({ commit }) {
      let res;
      try {
        commit("setSpinnerShow", true, { root: true });

        res = await get_list();

        commit("setList", res.data.data);
        commit(
          "setSnackbar",
          {
            text: res.data.message,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
  },
};
