import { get_sql_reports } from "@/helpers/api/SQLReports/index.js";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    listSQLReports: [],
    listSQLReportsNames: [],
  },
  mutations: {
    setListSQLReports(state, newState) {
      state.listSQLReports = newState;
    },
    setListSQLReportsNames(state, newState) {
      state.listSQLReportsNames = newState;
    },
  },
  actions: {
    async getSQLReport({ commit, state }, queryStrings) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_sql_reports(queryStrings);

        if (queryStrings.hasOwnProperty("getList")) {
          commit("setListSQLReportsNames", res);
        } else {
          commit("setListSQLReports", res);
        }
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Loaded`,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
  },
};
