import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});

export default new Vuetify({
  theme: {
    dark: true,
    options: { customProperties: true },
    themes: {
      dark: {
        primary: "#3284f4",
        secondary: "#f085ff",
        accent: "#fff",
        error: "#FF5252",
        info: "#fff",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#1E1E1E",
        gray_light: "#303030",
        transparent: colors.transparent,
        dark_blue: "#262737",
        darker_blue: "#20212E",
        primary_light: "#7db8ff",
      },
    },
  },
});
