import {
  get_customer_depot,
  save_customer_depot,
  delete_depot,
  save_tbd_containers,
  get_good_reefers,
  get_bad_reefers,
} from "@/helpers/api/indexv2.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    customersDepot: [],
    idRowSelected: null,
    inventoryQtySumms: [],
    coNames: null,
    auctionFormFlag: false,
    goodRefeers: [],
    badRefeers: [],
  },
  mutations: {
    setCoNames(state, newState) {
      state.coNames = newState;
    },
    setCustomersDepot(state, newState) {
      state.customersDepot = newState;
    },
    setInventoryQtySumms(state, newState) {
      state.inventoryQtySumms = newState;
    },
    setAuctionFormFlag(state, newState) {
      state.auctionFormFlag = newState;
    },
    setGoodRefeers(state, newState) {
      state.goodRefeers = newState;
    },
    setBadRefeers(state, newState) {
      state.badRefeers = newState;
    },
  },
  getters: {
    getInventoryQtySummsAvailables(state, getters, rootState) {
      let items = state.inventoryQtySumms.filter(
        (x) => x.QtyOnHandAvailable > 0 && x.Depot,
      );
      if (rootState.moduleOrders.moduleDetails.flagAddingDVContainer) {
        const Location =
          rootState.moduleOrders.moduleDetails.affectedDVContainer.Location;

        items = items.filter((x) => x.Depot === Location);
      }

      return items;
    },
    getInventoryQtySummsAvailablesQtys(state, getters) {
      let obj = {};
      getters.getInventoryQtySummsAvailables.map(
        (x) => (obj[`${x.Depot}_${x.Size}_${x.Type}`] = x.QtyOnHandAvailable),
      );
      return obj;
    },
    getGoodRefeersWithGroup(state, getters) {
      let goodRefeers = state.goodRefeers.filter(
        (x) => x.QtyOnHandTotalSell > 0 && x.DepotId && x.Depot,
      );
      return goodRefeers.map((x) => {
        return {
          ...x,
          group: `${x.CityName}${x.Depot}${x.Size}${x.Type}${x.RFMachineryMFG}${x.RFMachineryModel}${x.MRComments}`,
          QtyOnHandAvailable: x.QtyOnHandTotalSell,
        };
      });
    },
    getBadRefeersWithGroup(state, getters) {
      let badRefeers = state.badRefeers.filter(
        (x) => x.QtyOnHandTotalSell > 0 && x.DepotId && x.Depot,
      );
      return badRefeers.map((x) => {
        return {
          ...x,
          group: `${x.CityName}${x.Depot}${x.Size}${x.Type}${x.RFMachineryMFG}${x.RFMachineryModel}${x.MRComments}`,
          QtyOnHandAvailable: x.QtyOnHandTotalSell,
        };
      });
    },
  },
  actions: {
    async getCustomersDepot({ commit }, item) {
      let data;
      commit("setSpinnerShow", true, { root: true });
      try {
        data = await get_customer_depot(item);

        commit("setCustomersDepot", data);
      } catch (error) {
        // Re enable when qty summ side panel is complete
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong  1007. ${
        //       error ? error.message : "Server problem"
        //     }`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async saveCustomersDepot(
      { commit, dispatch },
      { customerOrganizationName, customerOrganizationId, info },
    ) {
      commit("setSpinnerShow", true, { root: true });
      try {
        Promise.all([
          await save_customer_depot({
            customerOrganizationId,
            vids_id: info.id,
          }),
          dispatch("getCustomersDepot", info),
        ]);

        commit(
          "setSnackbar",
          {
            text: "Depot Customer Saved!",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong 1008. ${
              error ? error.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async deleteDepot({ commit, dispatch }, item) {
      console.log("deleteDepot item", item);
      let { customerOrganizationId, vids_id } = item;

      commit("setSpinnerShow", true, { root: true });
      try {
        await delete_depot({ customerOrganizationId, vids_id });
        await dispatch("getCustomersDepot", { id: vids_id });
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong 1009. ${
              error ? error.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async saveTBDContainers({ commit }, { data }) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await save_tbd_containers({ data });

        commit(
          "setSnackbar",
          {
            text: "TBD container saved",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: "Error. Something went wrong",
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        throw error;
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getGoodReefers({ commit }) {
      try {
        let res = await get_good_reefers();
        commit("setGoodRefeers", res.data.data);
      } catch (error) {}
    },
    async getBadReefers({ commit }) {
      try {
        let res = await get_bad_reefers();
        commit("setBadRefeers", res.data.data);
      } catch (error) {}
    },
  },
};
