import { get_inventory_warnings } from "@/helpers/api/InventoryWarnings/index.js";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    listInventoryWarnings: [],
  },
  mutations: {
    setListInventoryWarnings(state, newState) {
      state.listInventoryWarnings = newState;
    },
  },
  actions: {
    async getInventoryWarnings({ commit, state }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_inventory_warnings();
        commit("setListInventoryWarnings", res);
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Loaded`,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
  },
};
