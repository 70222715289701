import store from "@/store/index";
import { isAuthenticaded, getUser } from "@/helpers/services/auth.js";
const beforeEachGuard = (to, from, next) => {
  let auth = isAuthenticaded();
  if (!to.meta.isPublic && !auth) {
    return next("/login");
  }
  if (auth) {
    let user = getUser();
    store.commit("set_user", JSON.parse(user));
  } else {
    store.commit("set_user", {});
    localStorage.removeItem("user");
    localStorage.removeItem("header");

    if (to.meta.isPublic && to.path === "/forgot-password") return next();
    if (to.path !== "/login") return next("/login");
  }
  if (to.name === "Login" && auth) {
    return next("/");
  }
  return next();
};

export { beforeEachGuard };
