import {
  save_contact_depot,
  get_contact_depot,
  update_contact_depot,
  delete_contact_depot,
  update_dates_status_depot,
  save_dates_status_depot,
} from "@/helpers/api/indexv2.js";

import { get_depot_all_sale_hold } from "@/helpers/api/Depots/index.js";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getContacts({ commit }, { DepotId }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await get_contact_depot(DepotId);

        // commit(
        //   "setSnackbar",
        //   {
        //     text: "Got Contacts",
        //     status: "success",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async updateContact({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await update_contact_depot(data);

        commit(
          "setSnackbar",
          {
            text: "Contact Updated",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async saveContact({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await save_contact_depot(data);

        commit(
          "setSnackbar",
          {
            text: "Contact saved",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async deleteContact({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await delete_contact_depot(data);

        commit(
          "setSnackbar",
          {
            text: "Contact Deleted",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },

    async updateDepotDate({ commit }, { data, cb }) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await update_dates_status_depot(data);

        if (cb) await cb();
      } catch (error) {
        throw error.response.data;
      }

      commit("setSpinnerShow", false, { root: true });
    },
    async saveDepotDate({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await save_dates_status_depot(data);

        // commit(
        //   "setSnackbar",
        //   {
        //     text: "Contact Updated",
        //     status: "success",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      } catch (error) {
        console.log("error");
        console.log(error);

        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong.`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async getDepotAllSaleHold({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        const res = await get_depot_all_sale_hold(data);

        commit(
          "setSnackbar",
          {
            text: "Got containers",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        commit("setSpinnerShow", false, { root: true });
        return res.data.data;
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        commit("setSpinnerShow", false, { root: true });
        throw error;
      }
    },
  },
};
