import { DATES, ENABLE_DATES } from "@/helpers/mocks/dates.js";
export const table_mixin = {
  computed: {
    isNotSOLDOrEXPIRE() {
      return (item) =>
        item.Status !== "OUT" &&
        item.Status !== "7" &&
        item.Status !== "EXPIRE" &&
        item.Status !== "4";
    },
    isInputDisable() {
      return (
        this.header.value === "BuyerParty" ||
        (this.header.value === "EquipmentID" && !this.item.newItem) ||
        (DATES.includes(this.header.value) &&
          !ENABLE_DATES.includes(this.header.value))
      );
    },
  },
  methods: {
    valueText(item, value) {
      console.log(item, value);
      item[value] = item[value].toUpperCase();
      if (item.newItem) {
        this.$emit("itemChanges", {
          id: item.id,
          add: true,
        });
      } else {
        let itemCopy = this.getInventoryFormatData.filter(
          (itemCopy) => itemCopy.id == item.id,
        )[0];

        this.$emit("itemChanges", {
          id: item.id,
          add: itemCopy[value] !== item[value],
        });
      }
    },
  },
};
