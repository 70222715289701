import {
  create_auction,
  get_auctions,
  get_auction_by_id,
  delete_auction,
  get_customers_bids,
  get_history_bids,
  get_customers,
  get_bids,
  update_auction,
  update_auction_line_items,
  update_lot_comments,
  delete_lot,
  get_winners,
  convert_auction,
  convert_auction_bulk,
  post_carbon_copy,
  get_carbon_copy,
  get_damage_details,
  get_last_cust,
  get_saved_auction,
  set_manual_winner,
  post_saved_auction,
  delete_saved_auction,
  add_customer,
} from "@/helpers/api/Auctions/index.js";
import {
  get_inventory_qty_summs,
  get_inventory_qty_summs_bypass_cache,
  get_coname_inventoryqtysumm,
} from "@/helpers/api/indexv2.js";
import { available_container_no } from "@/helpers/api/Orders/index.js";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    batchInfo: undefined,
    listAuctions: [],
    page: 1,
    searchAuction: "",
    // customerBids: {},
    historyBids: {},
    detailsItemId: null,
    formData: {},
    savedAuction: null,
  },
  getters: {
    customerBids(state) {
      let obj = {};

      Object.values(state.historyBids).map((x) => {
        const customerBids = x.reduce((acc, item) => {
          acc[item.organization_descr] = acc[item.organization_descr]
            ? acc[item.organization_descr]
            : [item];

          return acc;
        }, {});

        obj[x[0].auctionItemID] = Object.values(customerBids).map((x) => x[0]);
      });
      return obj;
    },
  },
  mutations: {
    addBatchInfo(state, newState) {
      state.batchInfo = newState;
    },
    setListAuctions(state, newState) {
      state.listAuctions = newState;
    },
    setCustomerBids(state, newState) {
      Vue.set(state.customerBids, newState.auctionItemID, newState.value);
    },
    setHistoryBids(state, newState) {
      Vue.set(state.historyBids, newState.auctionItemID, newState.value);
    },
    setSearchAuction(state, newState) {
      state.searchAuction = newState;
    },
    setDetailsItemId(state, newState) {
      state.detailsItemId = newState;
    },
    setFormData(state, newState) {
      state.formData = newState;
    },
    setPage(state, newState) {
      state.page = newState;
    },
    setSavedAuction(state, newState) {
      state.savedAuction = newState;
    },
  },
  actions: {
    async addCustomer({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await add_customer(data);

        commit(
          "setSnackbar",
          {
            text: res.data.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async createAuction({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await create_auction(data);

        commit(
          "setSnackbar",
          {
            text: res.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getAuctions({ commit, state }, { offset, LIMIT = 20, status = "A" }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_auctions({
          offset,
          search: state.searchAuction,
          LIMIT,
          status,
        });
        commit("setListAuctions", res);
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getAuctionInfo({ commit }, { id }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_auction_by_id({ id });
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getAuctionInfoNoErrorMsg({ commit }, { id }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_auction_by_id({ id });
      } catch (error) {}
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getAuctionInfoBackground({ commit }, { id }) {
      // commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_auction_by_id({ id });
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      // commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getBidsOverview({ commit }, { id }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_bids({ id });
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getBids({ commit }, { auctionId, auctionItemID }) {
      try {
        const res = await Promise.all([
          get_customers_bids({ auctionId, auctionItemID }),
          get_history_bids({ auctionId, auctionItemID }),
        ]);

        commit("setCustomerBids", {
          auctionItemID: auctionItemID,
          value: res[0],
        });
        commit("setHistoryBids", {
          auctionItemID: auctionItemID,
          value: res[1],
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getBidsAll({ commit }, { items }) {
      console.log(items);
      try {
        items.forEach(async (x) => {
          const res = await Promise.all([
            get_customers_bids({
              auctionId: x.auctionId,
              auctionItemID: x.auctionItemID,
            }),
            get_history_bids({
              auctionId: x.auctionId,
              auctionItemID: x.auctionItemID,
            }),
          ]);

          commit("setCustomerBids", {
            auctionItemID: x.auctionItemID,
            value: res[0],
          });
          commit("setHistoryBids", {
            auctionItemID: x.auctionItemID,
            value: res[1],
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getCustomers({ commit }, { auctionId }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_customers({ auctionId });
      } catch (error) {
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });

      return res;
    },
    async getWinners({ commit }, { auctionid }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_winners({ auctionid });
      } catch (error) {
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });

      return res;
    },
    async deleteAuction(
      { commit, dispatch },
      { id, auctionName, action, status }
    ) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await delete_auction({ id, action });
        // setTimeout(async () => {
        //   commit("setSpinnerShow", true, { root: true });

        await dispatch("getAuctions", { offset: 0, status });
        //   commit("setSpinnerShow", false, { root: true });
        // }, 2000);
        // console.log(res);
        commit(
          "setSnackbar",
          {
            text: `auction ${auctionName} is now inactive`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    async updateAuction({ commit, dispatch }, { auctionId, data }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await update_auction({ auctionId, data });

        commit(
          "setSnackbar",
          {
            text: res.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );

        await dispatch("getAuctions", { offset: 0 });
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${error.response.data.error}`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
        commit("setSpinnerShow", false, { root: true });

        throw error;
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async updateAuctionLineItems({ commit, dispatch }, { auctionId, data }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await update_auction_line_items({ auctionId, data });

        commit(
          "setSnackbar",
          {
            text: res.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );

        await dispatch("getAuctions", { offset: 0 });
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${error.response.data.error}`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
        commit("setSpinnerShow", false, { root: true });

        throw error;
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async updateLotComments({ commit }, { data }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await update_lot_comments({ data });

        commit(
          "setSnackbar",
          {
            text: res.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async deleteLot({ commit }, auctionItemID) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await delete_lot(auctionItemID);

        commit(
          "setSnackbar",
          {
            text: res.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async convertAuction({ commit, dispatch, rootState }, data) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      let flag = false;
      try {
        let orderObj = {};
        await Promise.all([
          dispatch("moduleOrders/getOrdersTerm", {}, { root: true }),
          dispatch("moduleOrders/getOrdersType", {}, { root: true }),
        ]);

        data.map((x) => {
          let lineItems = [];
          if (!!x.containers.length) {
            if (x.containers.length === x.qty) {
              x.containers.map(({ ContainerNo }) => {
                lineItems.push({
                  equipmentSize: x.CntrSize,
                  equipmentType: x.CntrType,
                  equipmentCondition: x.conditionDescription,
                  depot: x.Location,
                  country: x.Country,
                  state: x.State,
                  city: x.City,
                  depotId: x.depotId,
                  qty: 1,
                  buyerPrice: x.MaxAmount,
                  total: 1 * x.MaxAmount,
                  temType: "Container",
                  newRow: false,
                  ContainerNo: ContainerNo,
                });
              });
            } else {
              x.containers.map(({ ContainerNo }) => {
                lineItems.push({
                  equipmentSize: x.CntrSize,
                  equipmentType: x.CntrType,
                  equipmentCondition: x.conditionDescription,
                  depot: x.Location,
                  country: x.Country,
                  state: x.State,
                  city: x.City,
                  depotId: x.depotId,
                  qty: 1,
                  buyerPrice: x.MaxAmount,
                  total: 1 * x.MaxAmount,
                  temType: "Container",
                  newRow: false,
                  ContainerNo: ContainerNo,
                });
              });
              lineItems.push({
                equipmentSize: x.CntrSize,
                equipmentType: x.CntrType,
                equipmentCondition: x.conditionDescription,
                depot: x.Location,
                country: x.Country,
                state: x.State,
                city: x.City,
                depotId: x.depotId,
                qty: Number(x.qty) - Number(x.containers.length),
                buyerPrice: x.MaxAmount,
                total:
                  (Number(x.qty) - Number(x.containers.length)) * x.MaxAmount,
                temType: "Container",
                newRow: false,
                ContainerNo: "",
              });
            }
          } else {
            lineItems.push({
              equipmentSize: x.CntrSize,
              equipmentType: x.CntrType,
              equipmentCondition: x.CntrCondition,
              depot: x.Location,
              country: x.Country,
              state: x.State,
              city: x.City,
              depotId: x.depotId,
              qty: x.qty,
              buyerPrice: x.MaxAmount,
              total: x.qty * x.MaxAmount,
              temType: "Container",
              newRow: false,
              ContainerNo: "",
            });
          }

          orderObj[x.CustomerId] = {
            transStepId: 2,
            orderTypeId: rootState.moduleOrders.ordersType[0].id,
            creditTermId: rootState.moduleOrders.ordersTerm[0].id,
            customerEntityId: x.customerEntityID,
            organization_address: x.organization_address,
            externalCustomerNumber: x.externalCustomerNumber,
            primary_email: x.primary_email,
            customerName: x.MaxBidder,
            totalQty: orderObj[x.CustomerId]?.totalQty
              ? orderObj[x.CustomerId]?.totalQty + x.qty
              : x.qty,
            total: orderObj[x.CustomerId]?.total
              ? orderObj[x.CustomerId]?.total + x.MaxAmount * x.qty
              : x.MaxAmount * x.qty,
            lineItems: orderObj[x.CustomerId]?.lineItems
              ? [...orderObj[x.CustomerId]?.lineItems, ...lineItems]
              : lineItems,
            auctionId: x.auctionId,
            auctionItemID: orderObj[x.CustomerId]?.auctionItemID
              ? [...orderObj[x.CustomerId].auctionItemID, x.auctionItemID]
              : [x.auctionItemID],
          };
        });
        // console.log(orderObj);
        console.log(Object.values(orderObj));
        // res = await Promise.all(
        //   Object.keys(orderObj).map((x) => {
        //     return convert_auction(orderObj[x]);
        //   }),
        // );

        res = await convert_auction_bulk(Object.values(orderObj));
        flag = res;

        commit(
          "setSnackbar",
          {
            text: "orders created",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return flag;
    },
    async saveCarbonCopyEmail({ commit }, email) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await post_carbon_copy({ email });
        commit(
          "setSnackbar",
          {
            text: "CC saved",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getCarbonCopy({ commit }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_carbon_copy();
      } catch (error) {
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });

      return res;
    },
    async getDamageDetails({ commit }, { containers }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await get_damage_details(containers);
      } catch (error) {
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });

      return res;
    },
    async getLastCustAuction({ commit }) {
      commit("setSpinnerShow", true, { root: true });
      let res;

      try {
        res = await get_last_cust();
      } catch (error) {
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getContainersNoAvailable({ commit }, item) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await available_container_no({
          status: 1,
          depot: item.Depot,
          size: item.Size,
          cndtn: item.Cndtn,
          type: item.Type,
        });
      } catch (error) {
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getLineItemsInTheBackground(
      { dispatch, commit, state },
      { data, time }
    ) {
      if (data) {
        let res = await Promise.all([
          get_bids({ id: data[0].auctionId, time }),
          // get_auction_by_id({ id: data[0].auctionId }),
        ]);

        const historyBids = res[0].reduce((acc, item) => {
          acc[item.auctionItemID] = acc[item.auctionItemID]
            ? [...acc[item.auctionItemID], item]
            : [item];
          return acc;
        }, {});

        Object.keys(historyBids).forEach((x) => {
          let value;
          if (state.historyBids[x]) {
            let stateValues = state.historyBids[x].map(
              (x) => `${x.date_created}_${x.organization_descr}`
            );
            value = [
              ...historyBids[x].filter(
                (x) =>
                  !stateValues.includes(
                    `${x.date_created}_${x.organization_descr}`
                  )
              ),
              ...state.historyBids[x],
            ];
          } else {
            value = historyBids[x];
          }

          commit("setHistoryBids", {
            auctionItemID: x,
            value,
          });
        });

        return;
      }
    },

    async getCustomersBids({ commit }, { auctionId, auctionItemID }) {
      try {
        const res = await get_customers_bids({ auctionId, auctionItemID });

        commit("setCustomerBids", {
          auctionItemID: auctionItemID,
          value: res,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async setManualWinner(
      { commit },
      { auctionId, auctionItemID, CustomerId }
    ) {
      let res;
      try {
        commit("setSpinnerShow", true, { root: true });

        res = await set_manual_winner({
          auctionId,
          auctionItemID,
          CustomerId,
        });
        commit(
          "setSnackbar",
          {
            text: res.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getSavedAuction({ commit }) {
      commit("setSpinnerShow", true, { root: true });

      try {
        const res = await get_saved_auction();
        if (Object.keys(res.data.data).length)
          commit("setSavedAuction", res.data.data);
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });

      return;
    },
    async postSavedAuction({ commit, dispatch }, data) {
      try {
        commit("setSpinnerShow", true, { root: true });
        await delete_saved_auction();
        await post_saved_auction(data);
        commit("setSavedAuction", data);
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });

      return;
    },
    async deleteSavedAuction({ commit }) {
      try {
        commit("setSpinnerShow", true, { root: true });

        await delete_saved_auction();
        commit("setSavedAuction", null);
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });

      return;
    },
    async getDataForAuction({ commit, dispatch }) {
      commit("setSpinnerShow", true, { root: true });

      let res = await Promise.allSettled([
        get_inventory_qty_summs(),
        dispatch("moduleOrganizations/getsOrganizations", {}, { root: true }),
        dispatch("moduleAuctions/getSavedAuction", {}, { root: true }),
        dispatch("moduleQtySumm/getGoodReefers", {}, { root: true }),
        dispatch("moduleQtySumm/getBadReefers", {}, { root: true }),
        dispatch("moduleCustomerDistribution/getList", {}, { root: true }),
      ]);
      commit(
        "moduleQtySumm/setInventoryQtySumms",
        res[0].value.data.mergedData,
        { root: true }
      ),
        commit("setSpinnerShow", false, { root: true });
    },
  },
};
