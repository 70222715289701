import {
  get_list,
  delete_list,
  post_list,
  edit_list,
} from "@/helpers/api/CustomerDistribution/index";

export default {
  namespaced: true,
  state: {
    lists: [],
  },
  mutations: {
    setList(state, newState) {
      state.lists = newState;
    },
  },
  actions: {
    async deleteList({ commit, dispatch }, listId) {
      let res;
      try {
        commit("setSpinnerShow", true, { root: true });

        res = await delete_list(listId);

        await dispatch("getList");

        commit(
          "setSnackbar",
          {
            text: "List deleted",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async getList({ commit }) {
      let res;
      try {
        commit("setSpinnerShow", true, { root: true });

        res = await get_list();
        let list = Object.keys(res.data.data).map((x) => {
          return {
            name: res.data.data[x][0].name,
            id: x,
            customers: res.data.data[x],
          };
        });

        commit("setList", list);
        commit(
          "setSnackbar",
          {
            text: res.data.message,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async postList({ commit, dispatch }, data) {
      let res;
      try {
        commit("setSpinnerShow", true, { root: true });

        res = await post_list(data);

        await dispatch("getList");

        commit(
          "setSnackbar",
          {
            text: "List Created",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async editList({ commit, dispatch }, data) {
      let res;
      try {
        commit("setSpinnerShow", true, { root: true });
        // console.log(data);
        res = await edit_list(data);

        await dispatch("getList");

        commit(
          "setSnackbar",
          {
            text: "List Updated",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
  },
};
